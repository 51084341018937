import { DigandoAffiliateIdCookieName } from '../constants/cookies';
import { getCookie } from 'cookies-next';

export const useWastebox = (): { affiliateId: string; } | undefined => {
  // Check if user has an affiliate cookie set and create affiliateId object for registration
  const affiliateId = getCookie(DigandoAffiliateIdCookieName);

  let affiliateData: { affiliateId: string } | undefined;

  if (affiliateId) {
    affiliateData = {
      affiliateId: affiliateId,
    };
  }

  return affiliateData;
};
