import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from 'react';
import dayjs from 'dayjs';
import { useRouter } from 'next/router';
import { useProductSearchValues } from './recoil/product-search';
import { useSetGlobalSearch } from './recoil/global-search';
import { IPropertyFilter } from '../hooks/use-product-search-helper';
import {
  ProductPropertyFragment,
  PropertyFilter,
} from '..//@types/codegen/graphql';
import {
  useProductConfiguratorValues,
  useSetProductConfigurator,
} from './recoil/product-configurator';

export type ISearchInputLocation = {
  name: string;
  placeId: string;
  origLocationName?: string,
} | null;

export enum SearchInputTermTypes {
  CATEGORY = 'category',
  PRODUCT_TYPE = 'product-type',
  TEXT = 'text',
}

export interface ISearchInputTerm {
  value: string;
  type: SearchInputTermTypes;
  data?: string;
}

export enum SuggestionTypes {
  TERM = 'term',
  LOCATION = 'location',
  DATE_START = 'date-start',
  DATE_END = 'date-end',
}

interface IGlobalSearchContextProps {
  isSuggestionVisible: boolean;
  suggestionType: SuggestionTypes | null;
  setSuggestionType: (type: SuggestionTypes) => void;
  openSuggestion: (type: SuggestionTypes) => void;
  closeSuggestion: (force?: boolean) => void;
  startDate: dayjs.Dayjs;
  setStartDate: (date: dayjs.Dayjs) => void;
  endDate: dayjs.Dayjs;
  setEndDate: (date: dayjs.Dayjs) => void;
  updateProperty: (key: string, value: number | string | null) => void;
  checkProperties: (newProperties: ProductPropertyFragment[]) => void;
}

const GlobalSearchContext = createContext<IGlobalSearchContextProps>({
  isSuggestionVisible: false,
  startDate: dayjs(),
  endDate: dayjs(),
  suggestionType: null,
  closeSuggestion: (): void => {
    // eslint-disable-next-line no-console
    console.error('Availability Context not ready yet.');
  },
  openSuggestion: (): void => {
    // eslint-disable-next-line no-console
    console.error('Availability Context not ready yet.');
  },
  setStartDate: (): void => {
    // eslint-disable-next-line no-console
    console.error('Availability Context not ready yet.');
  },
  setEndDate: (): void => {
    // eslint-disable-next-line no-console
    console.error('Availability Context not ready yet.');
  },
  setSuggestionType: (): void => {
    // eslint-disable-next-line no-console
    console.error('Availability Context not ready yet.');
  },
  updateProperty: (): void => {
    // eslint-disable-next-line no-console
    console.error('ProductConfiguratorContext is not ready yet.');
  },
  checkProperties: (): void => {
    // eslint-disable-next-line no-console
    console.error('ProductConfiguratorContext is not ready yet.');
  },
});

interface IAvailabilityWrapperProps {
  tenantKey?: string;
  children: React.ReactNode;
}

let closeTimeout: NodeJS.Timeout | undefined;

export const GlobalSearchWrapper: FC<IAvailabilityWrapperProps> = (props) => {
  const router = useRouter();
  let variants: PropertyFilter[] = [];

  try {
    const variantsJSON: string =
      (router.query?.variants as string) ?? JSON.stringify([]);

    if ('' !== variantsJSON) {
      variants = JSON.parse(variantsJSON) as IPropertyFilter[];
    }
  } catch (error: unknown) {
    // This means there was no json passed as url props.
  }

  const { children } = props;
  const { filterValues } = useProductSearchValues();
  const { setTerm } = useSetGlobalSearch();
  const { properties } =
    useProductConfiguratorValues();
  const { setProperties } =
    useSetProductConfigurator();
  const [isSuggestionVisible, setIsSuggestionVisible] =
    useState<boolean>(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs>(
    filterValues.dateRange.gte
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs>(
    filterValues.dateRange.lte
  );
  const [suggestionType, setSuggestionType] = useState<SuggestionTypes>(
    SuggestionTypes.TERM
  );

  useEffect(() => {
    setProperties(variants);
  }, []);

  useEffect(() => {
    setProperties(variants);
  }, [router.query?.variants]);

  const updateProperty = (key: string, value: number | string | null): void => {
    const newProperties = properties.filter((property) => property.key !== key);

    if (null !== value) {
      newProperties.push({
        key,
        value,
      });
    }

    setProperties(newProperties);
  };

  const checkProperties = (newProperties: ProductPropertyFragment[]): void => {
    const checkedProperties = properties.filter((property) => {
      const possibleValues =
        newProperties.find((p) => p.key === property.key)?.values ?? [];

      return possibleValues.includes(property.value);
    });

    if (JSON.stringify(checkedProperties) !== JSON.stringify(properties)) {
      setProperties(checkedProperties);
    }
  };

  const closeSuggestion = (force = false): void => {
    if (force) {
      setIsSuggestionVisible(false);
    } else {
      closeTimeout = setTimeout(() => setIsSuggestionVisible(false), 100); // @fixme: we need to handle the close on a other way, 300ms will fix them for the first time.
    }
  };

  useEffect(() => {
    if (router.isReady && router.query?.q) {
      setTerm({
        value: (router.query?.q as string) ?? '',
        type: SearchInputTermTypes.TEXT,
      });
    }
  }, [router.isReady, router.query]);

  const openSuggestion = (type: SuggestionTypes): void => {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
    }

    setSuggestionType(type);
    setIsSuggestionVisible(true);
  };

  return (
    <>
      <GlobalSearchContext.Provider
        value={{
          updateProperty,
          checkProperties,
          openSuggestion,
          closeSuggestion,
          setStartDate,
          setEndDate,
          setSuggestionType,
          isSuggestionVisible,
          startDate,
          endDate,
          suggestionType,
        }}
      >
        {children}
      </GlobalSearchContext.Provider>
    </>
  );
};

export function useGlobalSearchContext(): IGlobalSearchContextProps {
  return useContext(GlobalSearchContext);
}
