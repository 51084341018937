import Bugsnag from '@bugsnag/js';

import {
  BookingListBookingFragment,
  CartDetailsFragment,
  ProductBundleQuery,
  ProductPageQuery,
} from '../../@types/codegen/graphql';
import { IBasketItemProps } from '../../components/basket/components/basket-item/basket-item';
import { DigandoAffiliateFirstVisitCookieName } from '../../constants/cookies';
import { deleteCookie } from 'cookies-next';

export const trackProductDetail = (productBundle: ProductPageQuery['productBundle']): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  const bundle = productBundle?.bundle;

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'view_item',
    ecommerce: {
      currency: 'EUR',
      items: [{
        item_name: bundle?.type.title ?? '', // Name or ID is required.
        item_id: bundle?.type.id ?? '',
        price: (bundle?.priceInformation?.productPricePerDay?.amount ?? 0) / 100,
        item_brand: bundle?.type.manufacturer?.title ?? '',
        item_category: bundle?.type.mainCategory?.title ?? '',
        item_variant: '', // @todo: Implement variant
        quantity: 1
      }]
    }
  });
};

export const trackAddProductToBasket = (productBundle: ProductBundleQuery['productBundle'], productPrice: ProductBundleQuery['productPrice']): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  const bundle = productBundle?.bundle;

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'add_to_cart',
    ecommerce: {
      currency: 'EUR',
      items: [{
        item_name: bundle?.type.title ?? '', // Name or ID is required.
        item_id: bundle?.type.id ?? '',
        price: (productPrice?.productPrice?.total?.amount ?? 0)  / 100,
        item_brand: bundle?.type.manufacturer?.title ?? '',
        item_category: bundle?.type.mainCategory?.title ?? '',
        item_variant: '', // @todo: Implement variant
        quantity: 1
      }]
    }
  });
};

export const removeProductToBasketTracking = (type: IBasketItemProps['type'], price: number): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'remove_from_cart',
    ecommerce: {
      currency: 'EUR',
      items: [{
        item_name: type?.title ?? '', // Name or ID is required.
        item_id: type?.id ?? '',
        price: price / 100,
        item_brand: type?.manufacturer?.title ?? '',
        item_variant: '', // @todo: Implement variant
        quantity: 1
      }]
    }
  });
};

export const purchasesBasket = (cart: CartDetailsFragment): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  const items = cart.items.map(item => {
    return {
      item_name: item.productType.title,
      item_id: item.productType.id,
      price: item.price / 100,
      item_brand: item.productType.manufacturer?.title ?? '',
      item_category: item.productType.mainCategory?.title ?? '',
      item_variant: '', // @todo: Implement variant
      quantity: 1
    };
  });

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'purchase',
    ecommerce: {
      transaction_id: cart.items[0].id,
      affiliation: 'Digando App',
      value: cart.totalPrice / 100,
      tax: cart.rentalTax / 100,
      currency: 'EUR',
      coupon: cart.coupon?.code ?? '',
      items: items
    }
  });
};

export const trackCancelBooking = (booking: BookingListBookingFragment): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'refund',
    ecommerce: {
      transaction_id: booking.id,
      affiliation: 'Digando App',
      value: booking.price.amount / 100,
      currency: 'EUR',
      items: [{
        item_name: booking.productType?.title,
        item_id: booking.productType?.id,
        item_variant: '', // @todo: Implement variant
        quantity: 1
      }]
    }
  });
};

export const newsletterSubscriptionTracking = (email: string): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'newsletter_subscription',
    email: email
  });
};

export const signUpFormRenderedTracking = (): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'sign-up_form_rendered',
  });
};

export const signUpFormCountrySelectedTracking = (countryCode: string): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'sign-up-country-selected',
    country: countryCode ?? ''
  });
};

export const signUpFormStepUserTracking = (): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'sign-up-user-form-reached',
  });
};

export const signUpTracking = (email: string, affiliateId?: string): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'sign-up',
    email: email,
    affiliateId: affiliateId ?? ''
  });
};

export const searchQueryTrack = (searchText: string, locationName: string, amountResults: number): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: 'custom_search',
    customSearchInput: searchText,
    location: locationName,
    amountResults: amountResults,
  });
};

/**
 * Affiliate tracking
 */
export const affiliateLinkFirstVisitTracking = (affiliateId: string): void | null => {
  if ('undefined' === typeof window || !window.dataLayer) {
    return null;
  }

  try {
    window.dataLayer.push({ affiliate: null }); // Clear the previous affiliate object.
    window.dataLayer.push({
      event: 'visit_affilate_page',
      affiliateId: affiliateId
    });

    // Delete first-time-visit cookie after successful tracking.
    deleteCookie(DigandoAffiliateFirstVisitCookieName);
  } catch (error) {
    Bugsnag.notify(`Error when tracking a user visit from affiliate with ID "${affiliateId}". Error: ${JSON.stringify(error)}`);
  }
};
