import { useEffect } from 'react';
import { getCookie } from 'cookies-next';

import { DigandoAffiliateFirstVisitCookieName, DigandoAffiliateIdCookieName } from '../../../constants/cookies';
import { affiliateLinkFirstVisitTracking } from '../../../lib/google/tracking-events';

/**
 * Affiliate Tracking
 *
 * Tracks only affiliates which visits the page the first time from an external link.
 * Affiliates receive a cookie through /api/affiliate/entrypoint from server-side.
 * If this is present, we send the GA4 event from client side and delete the cookie afterwards.
 */
export default function useAffiliateLinkVisitTracking(): void {
  // Do nothing when not running on client-side with a global dataLayer object
  if ('undefined' === typeof window || !window.dataLayer) {
    return;
  }

  const affiliateId = getCookie(DigandoAffiliateIdCookieName) ?? '';
  const isFirstVisit = 'true' === getCookie(DigandoAffiliateFirstVisitCookieName);

  useEffect(() => {
    if (isFirstVisit && 0 < affiliateId.length) {
      affiliateLinkFirstVisitTracking(affiliateId);
    }
  }, []);
}
